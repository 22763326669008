import LOCATIONS from './locations';

const TYPES = [
	{ label: 'Empresa' },
	{ label: 'Associació' },
	{ label: 'Fundació' },
	{ label: 'ONG' },
	{ label: 'Administració Pública' },
];

const SECTORS = [
	{ id: 'A', label: 'Agricultura, ramaderia, silvicultura i pesca' },
	{ id: 'B', label: 'Indústries extractives' },
	{ id: 'C', label: 'Indústries manufactureres' },
	{ id: 'D', label: 'Energia elèctrica, gas, vapor i aire condicionat' },
	{ id: 'E', label: 'Aigua, sanejament i gestió de residus' },
	{ id: 'F', label: 'Construcció' },
	{ id: 'G', label: 'Comerç a l’engròs i al detall' },
	{ id: 'H', label: 'Transport i emmagatzematge' },
	{ id: 'I', label: 'Hostaleria' },
	{ id: 'J', label: 'Informació i comunicacions' },
	{ id: 'K', label: 'Activitats financeres i d’assegurances' },
	{ id: 'L', label: 'Activitats immobiliàries' },
	{ id: 'M', label: 'Activitats professionals, científiques i tècniques' },
	{ id: 'N', label: 'Activitats administratives i serveis auxiliars' },
	{ id: 'O', label: 'Administració pública, Defensa i Seguretat Social obligatòria' },
	{ id: 'P', label: 'Educació' },
	{ id: 'Q', label: 'Activitats sanitàries i de serveis socials' },
	{ id: 'R', label: 'Activitats artístiques, recreatives i d’entreteniment' },
	{ id: 'T', label: 'Activitats de les llars' },
	{ id: 'U', label: 'Activitats d’organitzacions i organismes extraterritorials' },
];

export { TYPES, LOCATIONS, SECTORS };
