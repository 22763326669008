<template lang="html">
	<fieldset class="multiple" :disabled="disabled">
		<span v-for="option in value" :key="option.id || option" class="label">
			{{ option.id || option }}
			<a v-if="!disabled" href="#" @click.prevent="remove(option)" class="delete">&times;</a>
		</span>
		<input v-bind="$attrs" v-model="newOption" />
	</fieldset>
</template>

<script>
export default {
	name: 'InputMultiple',
	props: {
		value: { type: Array, default: () => [] },
		validator: { type: Function, required: true },
		disabled: { type: Boolean, default: false },
	},
	data() {
		return { newOption: undefined };
	},
	watch: {
		newOption(value) {
			this.add(value);
		},
	},
	methods: {
		add(value) {
			if (this.validator(value)) {
				this.$emit('input', [...this.value, value]);
				this.newOption = undefined;
			}
		},
		remove(value) {
			this.$emit('input', this.value.filter((option) => option !== value));
		},
	},
};
</script>
